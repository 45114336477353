import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { ICurrentUserBaasClient } from '@entities';
import { useCurrentUser } from '@hooks';
import { FullScreenSpin } from '@components';

export let SelectedClientContext = React.createContext({
  onChange: (clientId: string) => {},
  currentClient: undefined,
  allClients: [],
  isLoading: false,
} as {
  onChange: (clientId: string) => void;
  currentClient: ICurrentUserBaasClient | undefined;
  allClients: ICurrentUserBaasClient[];
  isLoading: boolean;
});

export function SelectedClientProvider({
  children,
}: PropsWithChildren<unknown>) {
  let { data, isLoading } = useCurrentUser();
  let [currentClient, setCurrentClient] = useState<
    ICurrentUserBaasClient | undefined
  >(undefined);

  let handleSetClient = useCallback(
    (clientId: string) => {
      setCurrentClient(
        data?.baasClients?.find((baasClient) => baasClient.id === clientId),
      );
    },
    [data?.baasClients],
  );

  let handleSetByDefault = useCallback(() => {
    if (!currentClient) {
      setCurrentClient(data?.baasClients?.[0]);
    }
  }, [currentClient, data?.baasClients]);

  useEffect(() => {
    handleSetByDefault();
  }, [data]);

  return (
    <SelectedClientContext.Provider
      value={{
        onChange: handleSetClient,
        currentClient,
        allClients: data?.baasClients ?? [],
        isLoading,
      }}
    >
      {!currentClient && <FullScreenSpin description="Loading your clients" />}
      {currentClient && children}
    </SelectedClientContext.Provider>
  );
}
